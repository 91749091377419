.Gallery {
  display: flex;
  font-family: Arial, sans-serif;
  flex-direction: column;
  margin-left: 100px;
  margin-right: 100px;
  padding-bottom: 50px;
}

.gallery-side-by-side {
  position: flex;
  flex-direction: row;
  text-align: left;
}

.gallery-h1 {
  text-align: left;
  font-family: "Josefin Sans";
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  color: #4F7889;
}

.gallery-text {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: black;
  margin-bottom: 30px;
}

.gallery-body {
  display: flex;
  flex-direction: column;
  border: 1px #0000004D;
  border-radius: 20px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  background-color: #FAFAFA;
}

.gallery-searchbar {
  display: 'flex';
  align-items: 'center';
  border: '1px solid #dce1e7';
  border-radius: '4px';
  width: '200px';
  padding: '15px';
}

.the-gallery {
  font-family: "Josefin Sans";
  font-size: 28px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: black;
}

.sidebar {
  width: 450px;
  background-color: #f8f8f8;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 20px 0 0 20px;
}

.search-bar {
  width: 20%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.person-list {
  list-style: none;
  padding-right: 0;
}

.person-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.person-item.active {
  background-color: #FFD582;
}

.person-img {
  width: 80px;
  height: auto;
  margin-right: 10px;
  object-fit: cover;
}

.person-info {
  display: flex;
  flex-direction: column;
}

.person-name {
  font-family: "Julius Sans One";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #264151;
}

.person-dates {
  color: #264151;
  font-family: "Josefin Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
}

.content {
  padding: 20px;
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-top: 10px;
}

.content h1 {
  margin-top: 0;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.gallery img {
  width: 100%;
  border-radius: 10px;
}

.gallery-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  justify-items: center;
  align-items: center;
  max-width: 80%;
  color: #ecf0f1;
  border-radius: 20px;
}

.gallery-image-container {
  width: 100%;
  height: 0;
  position: relative;
}

.gallery-image {
  position: absolute;
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-image-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.thumbnail {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.expanded {
  max-width: 100%;
  max-height: 50%;
  width: 100%;
  height: auto;
  transition: all 0.3s ease;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.obituary-details {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: rgb(250,250,250);
}

.obituary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  cursor: pointer;
}

.obituary-item.active {
  background-color: #FFD582;
}

.obituary-side-by-side {
  position: flex;
  flex-direction: row;
  text-align: left;
}

.gallery-buttons {
  display: flex;
  flex-direction: row;
  text-align: right;
}

.gallery-share-btn {
  border: 0;
  background-color: #f8f8f8;
  cursor: pointer;
}

.gallery-side-by-side {
  display: flex;
  align-items: right;
  justify-content: space-between;
}

@media (min-width: 1250px) {
  .gallery-gallery {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 100px;
  }
  .gallery-image-container {
    padding-bottom: 75%;
  }
}

@media (max-width: 1249px) {
  .Gallery {
    margin-left: 20px;
    margin-right: 20px;
  }

  .gallery-gallery {
    grid-template-columns: 1fr;
    padding: 30px;
  }
  .gallery-image-container {
    padding-bottom: 120%;
  }

  .gallery-buttons {
    text-align: left;
  }

  .gallery-image-container.fullscreen {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
}
