body, html {
  font-family: "Josefin Sans";
  margin: 0;
  padding: 0;
}

.userm_page {
  display: flex;
}

.userm_header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  font-family: "Josefin Sans";
  position: fixed;
  top: 0px;
  z-index: 8000;
}

.userm_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.userm_main-content {
  flex: 1;
  padding: 10px;
}

.userm_form {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  flex-direction: column;
  width: 85%;
  order: 2;
}

.userm_form h2 {
  font-size: 24px;
  margin: 0 0 10px;
}

.userm_form p {
  color: #555;
  margin: 0 0 20px;
}

.userm_form-group {
  margin-bottom: 10px;
}

.userm_form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.userm_form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.userm_form-group select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.userm_form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.userm_create-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background-color: #FFD074;
  color: #4F7889;
  font-family: "Josefin Sans";
}

.userm_side-by-side {
  display: flex;
  align-items: center;
  padding: 0px;
  position: relative;
}

.userm_side_description {
  order: 1;
  margin-right: 5px;
}

.userm_search-bar {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.userm_search-button {
  padding: 10px 20px;
  border-radius: 20px;
  border: 0px;
  background-color: #264151;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
}

.userm_deactive_btn {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  background-color: #F8AA8E;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
}

.userm_activate_btn {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  background-color: #007ec1;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
}

@media (min-width: 768px) {
  .userm_side-by-side {
    flex-direction: row;
  }
  .userm_header-container {
    width: 79%;
  }
  .userm_header{
    flex-direction: row;
  }
  .userm_side_description {
    width: 15%;
    text-align: left;
  }
  .userm_form {
    text-align: left;
  }
  .userm_form-group input {
    width: 60%;
  }
  .userm_form-actions {
    flex-direction: row;
  }
  .userm_main-content {
    margin-left: 20%;
  }
  .userm_search-bar {
    width: 200px;
    margin-bottom: 0px;
  }
  .userm_search-button {
    margin-left: 10px;
  }
  .userm_title {
    text-align: left;
  }
  .userm_body {
    margin-top: 150px;
  }
}

@media (max-width: 767px) {
  .userm_side-by-side {
    flex-direction: column;
  }
  .userm_header-container {
    width: 55%;
  }
  .userm_header{
    flex-direction: column;
  }
  .userm_side_description {
    width: 100%;
    text-align: center;
  }
  .userm_form {
    text-align: center;
  }
  .userm_form-group input {
    width: 95%;
  }
  .userm_form-actions {
    flex-direction: column;
  }
  .userm_main-content {
    margin-left: 40%;
  }
  .userm_search-bar {
    width: 100px;
    margin-bottom: 5px;
  }
  .userm_search-button {
    margin-left: 0px;
    margin-bottom: 10px
  }
  .userm_title {
    text-align: center;
  }
  .userm_body {
    margin-top: 230px;
  }
}

.userm_responsive-table {
  padding-right: 40px;
  padding-inline-start: 0px;
  font-family: "Josefin Sans";

  li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .table-header {
    background-color: #4F7889;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: white;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    cursor: pointer;
  }
  .col-1 {
    flex-basis: 20%;
  }
  .col-2 {
    flex-basis: 25%;
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-4 {
    flex-basis: 10%;
  }
  .col-5 {
    flex-basis: 20%;
  }
  
  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: left;
      }
    }
  }
}