/* General styling */
body {
  font-family: "Josefin Sans";
  margin: 0;
  padding: 0;
}

.header_acc {
  background-color: #FFD28E;
  padding: 20px;
  text-align: center;
}

.logo-placeholder_acc {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  display: inline-block;
}

.content_acc {
  padding: 20px;
}

.h2_acc {
  font-family: "Josefin Sans";
  color: #333;
}

.h3_acc {
  font-family: "Josefin Sans";
  font-size: 22px;
  font-weight: 500;
  line-height: 25.85px;
  text-decoration-skip-ink: none;
}

.p_acc {
  font-family: "Josefin Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 16.45px;
  text-decoration-skip-ink: none;  
}

.flex_acc-back_btn {
  display: flex;
}

.input_acc {
  position: flex;
  flex-direction: column;
}

.inputbox_acc {
  width: 70%;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.flex_acc {
  display: flex;
  justify-content: center;
}

.back-btn_acc {
  font-family: "Josefin Sans";
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.button_acc {
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary_acc {
  background-color: #4F7889;
  margin-top: 20px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  border: 2px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Josefin Sans";
  font-weight: 400;
  font-size: 18px;
  color: white;
}

.btn-primary_acc:hover {
  background-color: #3c5b68;
  cursor: pointer;
}

.btn-secondary_acc {
  margin-top: 20px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  border: 2px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Josefin Sans";
  font-weight: 400;
  font-size: 18px;
  color: black;
}

.btn-secondary_acc:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.social-login-icons_acc span {
  margin: 0 10px;
}

/* Desktop layout */
@media (min-width: 768px) {
  .flex_acc-back_btn {
    justify-content: center;
  } 

  .h3_acc {
    text-align: center;
  }

  .p_acc {
    text-align: center;
  }

  .content_acc {
    max-width: 600px;
    margin: auto;
  }

  .header_acc {
    padding: 30px;
  }

  .button_acc {
    padding: 12px;
    font-size: 18px;
  }

  .btn-primary_acc {
    width: 50%;
  }

  .btn-secondary_acc {
    width: 50%;
  }

  .social-login-icons_acc span {
    margin: 0 15px;
  }
}

/* Mobile layout */
@media (max-width: 767px) {
  .header_acc {
    padding: 15px;
  }

  .logo-placeholder_acc {
    width: 40px;
    height: 40px;
  }

  .flex_acc-back_btn {
    justify-content: left;
  }

  .h3_acc {
    text-align: left;
  }

  .p_acc {
    text-align: left;
  }

  .content_acc {
    padding: 15px;
  }

  .h2_acc {
    font-size: 20px;
  }

  .button_acc {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }

  .btn-primary_acc {
    width: 80%;
  }

  .btn-secondary_acc {
    width: 80%;
  }

  .social-login-icons_acc span {
    margin: 0 5px;
  }
}
