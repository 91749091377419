.obituary-page {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-right: 100px;
  background-color: white;
}

.obituary-space-between {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.obituary-h1 {
  font-family: "Josefin Sans";
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #4F7889;
}

.obituary-text {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #264151;
}

.obituary-side-by-side {
  position: flex;
  flex-direction: row;
  text-align: left;
}

.obituary-search-bar {
  width: 200px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.obituary-search-button {
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 20px;
  border: 0px;
  background-color: #264151;
  color: white;
}

.obituary-inputContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-content: center;
}

.obituary-label {
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.obituary-inputDate {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.obituary-applyButton {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #2b3a4e;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.obituary-page-recent-obituaries {
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 30px;
}

.obituary-list {
  padding: 0px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.obituary-card {
  margin: 5px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px;
  height: 80px;
  text-align: center;
  cursor: pointer;
  display: flex;
}

.obituary-card-info {
  margin-top: 0px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.obituary-card-info-span-1 {
  font-family: "Josefin Sans";
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.obituary-card-h3 {
  margin-top: 0px;
  font-family: "Julius Sans One";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: left;
}

.obituary-card-p {
  font-family: "Josefin Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.obituary-item {
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  cursor: pointer;
}

.obituary-item.active {
  background-color: #FFD582;
  padding: 5px 10px;
}

.obituary-image {
  width: 30%;
  height: auto;
  border-radius: 8px;
}

.obituary-details {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: rgb(250,250,250);
}

h2, h3 {
  color: #3c3c3c;
}

.share-link {
  display: inline-block;
  margin-top: 20px;
  font-weight: bold;
  color: #3498db; /* Link color */
}

.obituary-bottom-info {
  width: 70%;
  background-color: #FAFAFA;
  border: 1px solid #0000004D;
  border-radius: 10px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 20px;
  align-self: center;
}

.obituary-bottom-info span {
  font-family: "Josefin Sans";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

.obituary-share-btn {
  border: 0;
  background-color: white;
  cursor: pointer;
}

.obituary-space-between2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
}

.obituary-waze-link {
  text-align: center;
}

.obituary-link {
  color: black;
}

.obituary-view-profile {
  margin-top: 10px;
}

.obituary-up-down {
  display: flex;
  flex-direction: column;
  font-family: "Josefin Sans";
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-left: 300px;
  margin-bottom: 20px;
}

.obituary-second-part {
  margin-top: 50px;
  width: 100%;
}

.obituary-second-part img {
  width: 100%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2em; /* Smaller heading on mobile */
  }

  .obituary-label {
    margin-left: 0px;
  }

  .obituary-inputDate {
    margin-left: 0px;
  }

  .obituary-applyButton {
    margin-left: 0px;
  }

  .obituary-page-recent-obituaries {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .obituary-inputContainer {
    flex-direction: column;
  }

  .obituary-page {
    margin-left: 20px;
    margin-right: 20px;
  }

  .obituary-list {
    grid-template-columns: 1fr 1fr;
  }

  .obituary-card {
    width: 100%;
    max-width: 140px;
    padding: 5px;
  }

  .obituary-card-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .obituary-image {
    width: 40%;
    height: 100%;
  }
  
  .obituary-card-info-span-1 {
    font-family: "Josefin Sans";
    font-size: 8px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
  
  .obituary-card-h3 {
    font-size: 8px;
    line-height: 10px;
  }
  
  .obituary-card-p {
    font-size: 8px;
    line-height: 10px;
  }

  .obituary-item {
    padding: 5px;
  }

  .obituary-waze-link {
    text-align: left;
  }

  .obituary-bottom-info {
    width: 90%;
    margin: 5px;
  }
}