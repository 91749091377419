:root {
  --vh: 1vh;
}

html, body {
  height: 100%;
  margin: 0;
}

.sidebar_AL {
  background-color: #2f5d6d;
  font-family: "Josefin Sans";
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
}

.logo_AL {
  font-size: 22px;
  margin-bottom: 20px;
  font-family: "Cormorant Garamond";
  font-weight: 700;
  text-align: left;
}

.menu_AL {
  flex-grow: 1;
}

.menu-item_AL {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  color: #ffffff;
}

.menu-item_AL:hover {
  background-color: #1f3e4a;
}

.link_AL {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color from parent */
}

.icon_AL {
  margin-right: 10px;
}

.sign-out_AL {
  margin-top: auto; /* Ensures sign-out stays at the bottom */
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2); /* Optional: a separator line */
}

.sign-out_AL button {
  color: white;
  border: none;
  background-color: #2f5d6d;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-family: "Josefin Sans";
}

.sign-out_AL button:hover {
  background-color: #1f3e4a;
  text-align: center;
}

@media (min-width: 768px) {
  .sidebar_AL {
    height: 98vh;
  }
  .text_AL {
    font-size: 18px;
  }

  .sidebar_AL {
    width: 200px;
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .sidebar_AL {
    height: calc(var(--vh, 1vh) * 100);
  }
  .logo_AL span {
    font-size: 12px;
  }

  .text_AL {
    font-size: 10px;
  }

  .sidebar_AL {
    width: 150px;
    padding: 5px;
  }
}