body, html {
  margin: 0;
  padding: 0;
}

.um_undertaker_page {
  display: flex;
}

.um_undertaker_header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  font-family: "Josefin Sans";
  position: fixed;
  top: 0px;
  z-index: 8000;
}

.um_undertaker_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.um_undertaker_main-content {
  flex: 1;
  padding: 10px;
  font-family: "Josefin Sans";
}

.um_undertaker_back-button {
  cursor: pointer;
  color: #555;
  font-size: 18px;
}

.um_undertaker_form {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin: 10px auto;
  flex-direction: column;
  width: 85%;
  order: 2;
}

.um_undertaker_form h2 {
  font-size: 24px;
  margin: 0 0 10px;
}

.um_undertaker_form p {
  color: #555;
  margin: 0 0 20px;
}

.um_undertaker_form-group {
  margin-bottom: 10px;
}

.um_undertaker_form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.um_undertaker_form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.um_undertaker_form-group select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.um_undertaker_form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.um_undertaker_cancel-button, .um_undertaker_save-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  font-family: "Josefin Sans";
}

.um_undertaker_cancel-button {
  border: 1px solid #007EC1;
  background-color: white;
  color: #5C5757;
}

.um_undertaker_save-button {
  border: none;
  background-color: #FFD074;
  color: #4F7889;
}

.um_undertaker_side-by-side {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  margin: 0px;
  position: relative;
}

.um_undertaker_side_description {
  order: 1;
  margin-right: 5px;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.spinner-text {
  color: white;
  margin-top: 15px;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

@media (min-width: 768px) {
  .um_undertaker_body {
    margin-top: 150px;
  }
  .um_undertaker_header-container {
    width: 79%;
  }
  .um_undertaker_side-by-side {
    flex-direction: row;
  }
  .um_undertaker_title {
    text-align: left;
  }
  .um_undertaker_side_description {
    width: 15%;
    text-align: left;
  }
  .um_undertaker_form {
    text-align: left;
  }
  .um_undertaker_form-group input {
    width: 60%;
  }
  .um_undertaker_form-actions {
    flex-direction: row;
  }
  .um_undertaker_main-content {
    margin-left: 20%;
  }
  .um_undertaker_header {
    flex-direction: row;
  }
  .um_undertaker_back-button {
    margin-bottom: 0px;
  }
  .textarea-service {
    max-width: 60%;
    height: 200px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .um_undertaker_body {
    margin-top: 230px;
  }
  .um_undertaker_header-container {
    width: 55%;
  }
  .um_undertaker_side-by-side {
    flex-direction: column;
  }
  .um_undertaker_title {
    text-align: center;
  }
  .um_undertaker_side_description {
    width: 100%;
    text-align: center;
  }
  .um_undertaker_form {
    text-align: center;
  }
  .um_undertaker_form-group input {
    width: 95%;
  }
  .um_undertaker_form-actions {
    flex-direction: column;
  }
  .um_undertaker_main-content {
    margin-left: 40%;
  }
  .um_undertaker_header {
    flex-direction: column;
  }
  .um_undertaker_back-button {
    margin-bottom: 5px;
  }
}