.user-reviews {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 40px;
  background-color: #f9f9f9;
}

.user-reviews-h2 {
  color: #4F7889;
  font-family: "Josefin Sans";
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
}

.user-reviews-span {
  color: #4F7889;
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.user-reviews-reviews-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
}

.user-reviews-review-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 300px;
  transition: transform 0.2s;
  position: relative;
  min-height: 300px;
  border: 1px solid #ccc;
  padding: 10px;
  display: grid;
  grid-template-rows: auto 1fr auto; /* Define the grid layout */   
}

.user-reviews-review-card p {
  color: #3F3F3F;
}

.user-reviews-bottom-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}

.user-reviews-review-date {
  font-size: 0.9em;
  color: #999;
}

.user-reviews-reviewer-name-div {
  bottom: 0;
  width: 100%;
  background-color: #4F7889;
  position: absolute;
  border-radius: 0 0 8px 8px;
  color: white;
}

.user-reviews-reviewer-name-div p {
  font-weight: bold;
  margin-top: 10px;
  color: white;
}

.user-reviews-navigation {
  margin-top: 20px;
}

.user-reviews-nav-button {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  margin: 0 10px;
}

@media (max-width: 768px) {
  .user-reviews-h2 {
    font-size: 1.5em;
  }

  .user-reviews-p {
    font-size: 1em;
  }

  .user-reviews-reviews-container {
    flex-direction: column;
    align-items: center;
  }

  .user-reviews-review-card {
    width: 90%;
    max-width: 400px;
  }

  .user-reviews-nav-button {
    flex-direction: row;
  }

  .user-reviews-nav-button {
    font-size: 1.5em;
  }
}