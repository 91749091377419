.JoinUs {
  background-color: #324C57;
  color: white;
  display: flex;
  position: relative;
  align-items: left;
  justify-content: left;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: column;
}

.joinUs-big-text {
  font-family: "Josefin Sans";
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
}

.joinUs-small-text{
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #FFFFFF;
}

.joinUs-getStartedBtn {
  display: flex;
  position: relative;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}

.joinUs-getStartedBtn button {
  margin-top: 20px;
  width: 125px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  border: 2px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Josefin Sans";
  font-weight: 400;
  font-size: 18px;
  color: #264151;
}

.joinUs-getStartedBtn button:hover {
  background-color: #d6d6d6;
}