.Intro {
  background-color: rgb(38 38 38);
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.intro-image {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
}

.intro-text {
  display: flex;
  padding: 20px;
  text-align: left;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.intro-bigText {
  font-family: "Josefin Sans";
  font-weight: 400;
  line-height: 50px;
}

.intro-smallText {
  font-family: "Josefin Sans";
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
}

@media (min-width: 1250px) {
  .Intro {
    flex-direction: row;
  }
  .intro-text {
    padding: 0px 20% 0px 30px;
  }
  .intro-image {
    flex-direction: row;
  }
  .intro-image img {
    width: 672px;
    height: 454px;
  }
  .intro-bigText {
    font-size: 55px;
    text-align: left;
  }
  .intro-smallText {
    text-align: left;
  }
  .intro-sec2 {
    display: flex;
    position: relative;
    margin-top: 50px;
  }
}

@media (max-width: 1249px) {
  .Intro {
    flex-direction: column;
  }
  .intro-image {
    flex-direction: column;
  }
  .intro-image img {
    max-width: 100%;
    height: auto;
  }
  .intro-bigText {
    font-size: 30px;
    text-align: center;
  }
  .intro-smallText {
    text-align: center;
  }
  .intro-sec2 {
    display: flex;
    position: relative;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
  .intro-getStartedBtn {
    align-items: center;
    justify-content: center;
  }
}

.intro-sec2 {
  display: flex;
  position: relative;
  margin-top: 50px;
}

.intro-b4stacks4 {
  flex-direction: column;
}

.intro-stacks4 {
  display: flex;
  margin-left: 50px;
}

.intro-getStartedBtn {
  display: flex;
  position: relative;
  margin-top: 20px;
}

.intro-getStartedBtn button {
  margin-top: 20px;
  width: 125px;
  height: 44px;
  padding: 10px 16px 10px 16px;
  border: 2px;
  border-radius: 10px;
  gap: 10px;
  font-family: "Josefin Sans";
  font-weight: 400;
  font-size: 18px;
  color: #264151;
}

.intro-getStartedBtn button:hover {
  background-color: #d6d6d6;
}