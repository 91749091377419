.header {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: white;
  color: rgb(38 65 81);
  position: relative;
  z-index: 100;
}

@media (max-width: 768px) {
  .logo span {
    font-size: 16px;
  }
  .login-button button {
    font-size: 16px;
  }
}

@media (min-width: 769px) {
  .logo span {
    font-size: 25px;
  }
  .login-button button {
    font-size: 18px;
  }
}

.logo {
  margin-top: 8px;
}

.logo span {
  font-family: "Cormorant Garamond";
  font-weight: 700;
  text-align: left;
  color: black;
}

.logo img {
  height: 50px;
  align-items: left;
}

.side-by-side {
  display: flex;
  align-items: right;
}

.navbar {
  flex: 1;
  margin-top: 15px;
  margin-right: 50px;
}

.navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  margin: 0 15px;
}

.navbar a {
  color: rgb(38 65 81);
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.navbar a:hover {
  text-decoration: underline;
}

.login-button button {
  background-color: rgb(79 120 137);
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 15px;
  font-family: "Josefin Sans";
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}

.login-button button:hover {
  background-color: rgb(61 91 105);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: grey;
    position: absolute;
    top: 60px;
    left: 0;
    padding: 10px 0;
  }

  .navbar.open {
    display: flex;
  }

  .navbar ul {
    flex-direction: column;
  }

  .navbar li {
    margin: 10px;
  }

  .menu-toggle {
    display: flex;
  }
}