.MemoriesGallery {
  background-color: white;
  text-align: center;
  margin: 20px;
}

@media (min-width: 1250px) {
  .memoriesGallery-title {
    font-size: 55px;
    text-align: left;
  }
  .memoriesGallery-subtitle {
    text-align: left;
  }
  .memoriesGallery-gallery {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 100px;
    margin-right: 100px;
  }
  .memoriesGallery-image-container {
    padding-bottom: 75%;
  }
  .memoriesGallery-image {
    object-fit: cover;
  }
}

@media (max-width: 1249px) {
  .memoriesGallery-title {
    font-size: 30px;
    text-align: center;
  }
  .memoriesGallery-subtitle {
    text-align: center;
  }
  .memoriesGallery-gallery {
    grid-template-columns: 1fr;
    padding: 30px;
  }
  .memoriesGallery-image-container {
    padding-bottom: 120%;
  }
  .memoriesGallery-image {
    object-fit: contain;
  }
}

.memoriesGallery-title {
  font-family: "Josefin Sans";
  font-weight: 400;
  line-height: 50px;
  color: #4F7889;
}

.memoriesGallery-subtitle {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #4F7889;
}

.memoriesGallery-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  justify-items: center;
  align-items: center;
  max-width: 80%;
  background-color: #2c3e50;
  color: #ecf0f1;
  border-radius: 20px;
}

.memoriesGallery-image-container {
  width: 100%;
  height: 0;
  position: relative;
  overflow: hidden;
}

.memoriesGallery-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.memoriesGallery-link {
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  justify-self: center;
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 1rem;
  color: white;
  text-decoration: underline;
}