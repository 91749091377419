.recent-obituaries {
  padding: 40px;
  background-color: #f9f9f9;
  display: flex;
  position: relative;
  flex-direction: row;
}

.recent-obituaries-h2 {
  color: #4F7889;
  font-family: "Josefin Sans";
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;

}

.recent-obituaries-p {
  color: #4F7889;
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
}

.recent-obituaries-obituaries-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping for mobile */
  margin: 20px 0;
}

.recent-obituaries-obituary-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 10px; /* Margin adjusted for spacing */
  padding: 20px;
  width: 200px; /* Default width */
  text-align: center;
  transition: transform 0.2s;
}

.recent-obituaries-obituary-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.recent-obituaries-obituary-dates {
  font-size: 0.9em;
  color: #999;
}

.recent-obituaries-navigation {
  margin-top: 20px;
}

.recent-obituaries-nav-button {
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  margin: 0 10px;
}

.recent-obituaries-see-all {
  color: #4F7889;
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  text-align: left;
}

.place-in-column {
  display: flex;
  flex-direction: column;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .recent-obituaries {
    display: flex;
    position: relative;
    flex-direction: column;
  }
  .recent-obituaries-h2 {
    font-size: 1.5em; /* Smaller heading on mobile */
    text-align: center;
  }

  .recent-obituaries-p {
    text-align: center;
  }

  .recent-obituaries-obituaries-container {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center cards */
  }

  .recent-obituaries-obituary-card {
    width: 50%; /* Full width on mobile */
    max-width: 300px; /* Maximum width */
  }

  .recent-obituaries-nav-button {
    flex-direction: row;
  }

  .recent-obituaries-nav-button-container {
    margin-top: 10px;
    flex-direction: row;
  }

  .recent-obituaries-see-all {
      font-size: 1em; /* Smaller link text */
  }
}