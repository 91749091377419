.Steps {
  background-color: #324C57;
  color: white;
  display: flex;
  position: relative;
  align-items: left;
  justify-content: left;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 1250px) {
  .Steps {
    flex-direction: row;
  }
  .steps-side-by-side {
    flex-direction: row;
  }
  .step1{
    display: flex;
    margin-left: 100px;
  }
  .step1-number {
    text-align: right;
    margin-right: 20px;
  }
  .step1-text-1 {
    text-align: left;
  }
  .step1-text-2 {
    text-align: left;
  }
  .step2{
    display: flex;
    margin-left: 100px;
    margin-right: 100px;
  }
  .step2-number {
    text-align: right;
    margin-right: 20px;
  }
  .step2-text-1 {
    text-align: left;
  }
  .step2-text-2 {
    text-align: left;
  }
  .step3{
    display: flex;
    margin-right: 100px;
  }
  .step3-number {
    text-align: right;
    margin-right: 20px;
  }
  .step3-text-1 {
    text-align: left;
  }
  .step3-text-2 {
    text-align: left;
  }
}

@media (max-width: 1249px) {
  .Steps {
    flex-direction: column;
  }
  .steps-side-by-side {
    flex-direction: column;
  }
  .step1{
    display: grid;
    margin-bottom: 50px;
  }
  .step1-number {
    text-align: center;
    margin-right: 0px;
  }
  .step1-text-1 {
    text-align: center;
  }
  .step1-text-2 {
    text-align: center;
  }
  .step2{
    display: grid;
    margin-bottom: 50px;
  }
  .step2-number {
    text-align: center;
    margin-right: 0px;
  }
  .step2-text-1 {
    text-align: center;
  }
  .step2-text-2 {
    text-align: center;
  }
  .step3{
    display: grid;
  }
  .step3-number {
    text-align: center;
    margin-right: 0px;
  }
  .step3-text-1 {
    text-align: center;
  }
  .step3-text-2 {
    text-align: center;
  }
}

.steps-side-by-side {
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
}

.step1-number {
  flex: 1;
  font-family: "Josefin Sans";
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
}

.step1-text-1 {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.step1-text-2 {
  font-family: "Josefin Sans";
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #F7F1E5;
}

.step2-number {
  flex: 1;
  font-family: "Josefin Sans";
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
}

.step2-text-1 {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.step2-text-2 {
  font-family: "Josefin Sans";
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #F7F1E5;
}

.step3-number {
  flex: 1;
  font-family: "Josefin Sans";
  font-size: 55px;
  font-weight: 400;
  line-height: 50px;
}

.step3-text-1 {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.step3-text-2 {
  font-family: "Josefin Sans";
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: #F7F1E5;
}