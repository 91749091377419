.uprofile-page {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-right: 100px;
  background-color: white;
}

.uprofile-space-between {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.uprofile-h1 {
  font-family: "Josefin Sans";
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  text-align: left;
  color: #4F7889;
}

.uprofile-text {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #264151;
}

.uprofile-side-by-side {
  position: flex;
  flex-direction: row;
  text-align: left;
}

.uprofile-search-bar {
  width: 200px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.uprofile-search-button {
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 20px;
  border: 0px;
  background-color: #264151;
  color: white;
}

.uprofile-inputContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-content: center;
}

.uprofile-label {
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
}

.uprofile-inputDate {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.uprofile-applyButton {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #2b3a4e;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.uprofile-details {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.2);
  background-color: rgb(250,250,250);
  margin-bottom: 10px;
  width: 50%;
}

.uprofile-page-recent-obituaries {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
}

.uprofile-page-recent-obituaries div {
  margin-bottom: 5px;
}

.uprofile-image {
  width: 30%;
  height: auto;
  border-radius: 8px;
}

h2, h3 {
  color: #3c3c3c;
}

.share-link {
  display: inline-block;
  margin-top: 20px;
  font-weight: bold;
  color: #3498db; /* Link color */
}

.uprofile-bottom-info {
  width: 70%;
  background-color: #FAFAFA;
  border: 1px solid #0000004D;
  border-radius: 10px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 20px;
  align-self: center;
}

.uprofile-bottom-info span {
  font-family: "Josefin Sans";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

.uprofile-share-btn {
  border: 0;
  background-color: white;
  cursor: pointer;
}

.uprofile-space-between2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;
}

.uprofile-waze-link {
  text-align: center;
}

.uprofile-link {
  color: black;
}

.uprofile-view-profile {
  margin-top: 10px;
}

.uprofile-up-down {
  display: flex;
  flex-direction: column;
  font-family: "Josefin Sans";
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-left: 300px;
  margin-bottom: 20px;
}

.uprofile-second-part {
  margin-top: 50px;
  width: 100%;
}

.uprofile-second-part img {
  width: 100%;
}

.preview_grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.preview_imageWrapper {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview_deleteButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
}

.preview_uploadPlaceholder {
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  color: #999;
}

@media (min-width: 768px) {
  .textarea-service {
    max-width: 60%;
    height: 200px;
    font-size: 16px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .uprofile-details {
    width: 90%;
  }

  h1 {
    font-size: 2em; /* Smaller heading on mobile */
  }

  .uprofile-label {
    margin-left: 0px;
  }

  .uprofile-inputDate {
    margin-left: 0px;
  }

  .uprofile-applyButton {
    margin-left: 0px;
  }

  .uprofile-page-recent-obituaries {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .uprofile-inputContainer {
    flex-direction: column;
  }

  .uprofile-page {
    margin-left: 20px;
    margin-right: 20px;
  }

  .uprofile-list {
    grid-template-columns: 1fr 1fr;
  }

  .uprofile-card {
    width: 100%;
    max-width: 140px;
    padding: 5px;
  }

  .uprofile-card-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .uprofile-image {
    width: 40%;
    height: 100%;
  }
  
  .uprofile-card-info-span-1 {
    font-family: "Josefin Sans";
    font-size: 8px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
  
  .uprofile-card-h3 {
    font-size: 8px;
    line-height: 10px;
  }
  
  .uprofile-card-p {
    font-size: 8px;
    line-height: 10px;
  }

  .uprofile-item {
    padding: 5px;
  }

  .uprofile-waze-link {
    text-align: left;
  }

  .uprofile-bottom-info {
    width: 90%;
  }
}