.footer {
  background-image: url("../../public/red-roses-bouquet-table\ 1.png");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: flex;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media (min-width: 769px) {
  .footer-navbar {
    margin-left: 100px;
  }
  .footer-logo span {
    font-size: 25px;
  }
  .footer-navbar a {
    font-size: 16px;
  }
  .footer-navbar li {
    text-align: left;
  }
  .footer p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .footer-navbar {
    margin-left: 30px;
  }
  .footer-logo span {
    font-size: 12px;
  }
  .footer-navbar a {
    font-size: 11px;
  }
  .footer-navbar li {
    text-align: center;
  }
  .footer p {
    font-size: 12px;
  }
}

.footer-navbar {
  flex: 1;
  margin-top: 12px;
}

.footer-side-by-side {
  display: flex;
  align-items: right;
  padding: 20px;
}

.footer-navbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-navbar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-navbar li {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 15px;
}

.navbar a:hover {
  text-decoration: underline;
}

.footer-logo {
  margin-top: 8px;
}

.footer-logo span {
  font-family: "Cormorant Garamond";
  font-weight: 700;
  text-align: left;
  color: White;
}

.footer-logo img {
  height: 50px;
  align-items: left;
}

footer p {
  font-family: "Josefin Sans";
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: white;
}