.slideshow-container {
  height: 100vh;
  width: 100vw;
  position: fixed; /* Change to fixed to cover the whole viewport */
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: black;
}

.slideshow {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.next-btn {
  position: absolute;
  bottom: 100px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
}

.next-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .slideshow {
    background-size: contain;
  }
}