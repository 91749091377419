.PartnerContact {
  background-color: white;
  color: #4F7889;
  display: flex;
  position: relative;
  align-items: left;
  justify-content: left;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 1250px) {
  .PartnerContact {
    flex-direction: row;
  }
  .partnerContact-side-by-side {
    flex-direction: row;
    justify-content: space-between;
  }
  .partner{
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    text-align: left;
    width: 30%;
  }
  .partner-big-text {
    margin-right: 20px;
  }

  .contact{
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    text-align: left;
  }
  .contact-title-text {
    margin-right: 20px;
  }
}

@media (max-width: 1249px) {
  .PartnerContact {
    flex-direction: column;
  }
  .partnerContact-side-by-side {
    flex-direction: column;
  }
  .partner{
    display: grid;
    margin-bottom: 50px;
  }
  .partner-big-text {
    text-align: center;
    margin-right: 0px;
  }
  .pst-text-1 {
    text-align: center;
  }
  .contact{
    display: grid;
    margin-bottom: 50px;
  }
  .contact-title-text {
    text-align: center;
    margin-right: 0px;
  }
  .cit-text-1 {
    text-align: center;
  }
}

.partnerContact-side-by-side {
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
}

.partner-big-text {
  font-family: "Josefin Sans";
  font-size: 35px;
  font-weight: 400;
  line-height: 50px;
}

.pst-text-1 {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.contact-title-text {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.cit-text-1 {
  font-family: "Josefin Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}