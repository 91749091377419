body, html {
  font-family: "Josefin Sans";
  margin: 0;
  padding: 0;
}

.obituarym_page {
  display: flex;
}

.obituarym_header-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  font-family: "Josefin Sans";
  position: fixed;
  top: 0px;
  z-index: 8000;
}

.obituarym_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.obituarym_main-content {
  flex: 1;
  padding: 10px;
}

.obituarym_form {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  flex-direction: column;
  width: 85%;
  order: 2;
}

.obituarym_form h2 {
  font-size: 24px;
  margin: 0 0 10px;
}

.obituarym_form p {
  color: #555;
  margin: 0 0 20px;
}

.obituarym_form-group {
  margin-bottom: 10px;
}

.obituarym_form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.obituarym_form-group input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.obituarym_form-group select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.obituarym_form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.obituarym_create-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background-color: #FFD074;
  color: #4F7889;
  font-family: "Josefin Sans";
}

.obituarym_side-by-side {
  display: flex;
  align-items: center;
  padding: 0px;
  position: relative;
}

.obituarym_side_description {
  order: 1;
  margin-right: 5px;
}

.obituarym_search-bar {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.obituarym_search-button {
  padding: 10px 20px;
  border-radius: 20px;
  border: 0px;
  background-color: #264151;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
}

.obituarym_btn {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  background-color: #4F7889;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
  margin-left: 5px;
}

.obituarym_depublish_btn {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  background-color: #F8AA8E;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
}

.obituarym_publish_btn {
  padding: 5px;
  border-radius: 20px;
  border: 0px;
  background-color: #FFD582;
  color: white;
  font-family: "Josefin Sans";
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8001;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.popup-image {
  max-width: 70%;
  height: auto;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .obituarym_side-by-side {
    flex-direction: row;
  }
  .obituarym_header-container {
    width: 79%;
  }
  .obituarym_header{
    flex-direction: row;
  }
  .obituarym_side_description {
    width: 15%;
    text-align: left;
  }
  .obituarym_form {
    text-align: left;
  }
  .obituarym_form-group input {
    width: 60%;
  }
  .obituarym_form-actions {
    flex-direction: row;
  }
  .obituarym_main-content {
    margin-left: 20%;
  }
  .obituarym_search-bar {
    width: 200px;
    margin-bottom: 0px;
  }
  .obituarym_search-button {
    margin-left: 10px;
  }
  .obituarym_title {
    text-align: left;
  }
  .obituarym_body {
    margin-top: 150px;
  }
}

@media (max-width: 767px) {
  .obituarym_side-by-side {
    flex-direction: column;
  }
  .obituarym_header-container {
    width: 55%;
  }
  .obituarym_header{
    flex-direction: column;
  }
  .obituarym_side_description {
    width: 100%;
    text-align: center;
  }
  .obituarym_form {
    text-align: center;
  }
  .obituarym_form-group input {
    width: 95%;
  }
  .obituarym_form-actions {
    flex-direction: column;
  }
  .obituarym_main-content {
    margin-left: 40%;
  }
  .obituarym_search-bar {
    width: 100px;
    margin-bottom: 5px;
  }
  .obituarym_search-button {
    margin-left: 0px;
    margin-bottom: 10px
  }
  .obituarym_title {
    text-align: center;
  }
  .obituarym_body {
    margin-top: 230px;
  }
}

.obituarym_responsive-table {
  padding-right: 40px;
  padding-inline-start: 0px;
  font-family: "Josefin Sans";

  li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .table-header {
    background-color: #4F7889;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: white;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
    cursor: pointer;
  }
  .col-1 {
    flex-basis: 10%;
  }
  .col-2 {
    flex-basis: 20%;
  }
  .col-3 {
    flex-basis: 10%;
  }
  .col-4 {
    flex-basis: 10%;
  }
  .col-5 {
    flex-basis: 5%;
  }
  .col-6 {
    flex-basis: 5%;
  }
  .col-7 {
    flex-basis: 15%;
  }
  .col-8 {
    flex-basis: 25%;
  }
  
  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: left;
      }
    }
  }
}